<template>
  <Registrants
    v-bind="$props"
    v-on="$listeners"
    >
  </Registrants>
</template>

<script>
import Registrants from './Registrants'
import moment from 'moment'

export default {
  name: 'Processed',
  components: {
    Registrants,
  },
  props: { 
    resourcePath: {
      type: String,
      default: "processing"
    },
    title: {
        type: String,
        default: "Paid and Approved"
    },
    subject: {
        type: String,
        default: "Paid and Approved Applicant"
    },
    headers: {
      type: Array,
      default: function () {
        return [
          {
            text: 'Id',
            value: this.$config('applicant.id'),
          },
          {
            text: 'Name',
            value: 'name',
            customValue: function (item) {
              return `${item.profile.last_name}, ${item.profile.first_name}`
            },
          },
          {
            text: 'Email',
            value: 'email'
          },
          {
            text: 'Date Registered',
            value: 'created_at',
            customValue: function (item) {
              return moment.unix(item.created_at).format('MMM. D, YYYY')
            }
          },
          {
            text: 'Phone',
            value: 'profile.phone',
          },
          {
            text: 'Status',
            value: 'profile.status',
            align: 'center'
          },
          {
            text: 'Action',
            value: 'action',
            align: 'center',
            filterable: false
          }
        ]
      }
    }
  }
}

</script>

