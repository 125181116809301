import Applicant, { key } from '../../api/applicant'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'

const pagination  = new Pagination(Applicant)

export const state = {
    ...pagination.state,
}

export const getters = {
    ...pagination.getters
}

export const actions = {
    ...pagination.actions,

    fetchPagination(context, payload) {
        return pagination.actions.fetchPagination(context, {
            ...payload,
            expand: 'profile.rating,profile.education,profile.ielts,profile.application,profile.address,profile.referrer,profile.immigration'
        })
    },

    download({ state }) {
        return Applicant.download(state.filter)
    },

    update({ commit, dispatch }, payload) {
        // TODO: add failed notification on all api request
        return Applicant.update(payload[key], payload.params).then(response => {
            if (response.status === 200) {
                commit('UPDATE_REGISTRANT', {
                    [key]: payload[key],
                    registrant: response.data
                })
                dispatch('notifications/addSuccessNotification', AppString.registrantUpdated, { root: true })
            }

            return response
        })
    },

    save({ commit, dispatch }, payload) {
        return Applicant.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_REGISTRANT', response.data)
                dispatch('notifications/addSuccessNotification', AppString.registrantCreated, { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, registrant) {
        return Applicant.delete(registrant[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_REGISTRANT', registrant)
                dispatch('notifications/addSuccessNotification', AppString.registrantDeleted, { root: true })
            }

            return response
        })
    },

    archive({ commit, dispatch }, registrant) {
        return Applicant.archive(registrant[key]).then(response => {
            if (response.status === 200) {
                commit('ARCHIVE_REGISTRANT', registrant)
                dispatch('notifications/addSuccessNotification', AppString.registrantArchived, { root: true })
            }

            return response
        })
    },

    deny({ commit, dispatch }, registrant) {
        return Applicant.deny(registrant[key], registrant.deny_reason).then(response => {
            const deniedAt = response.data.denied_at
            if (response.status === 200) {
                commit('DENY_REGISTRANT', { registrant, deniedAt} )
                dispatch('notifications/addSuccessNotification', AppString.registrantDenied, { root: true })
            }

            return response
        })
    },

    // NOTE: Must be the same with modules/archivedApplicants.js
    view(_, registrant) {
        return Applicant.view(registrant[key]).then(response => {
            if (response.status === 200) {
                const data = response.data
                return data

                // return {
                //     id: data.id,
                //     profileId: data.profile?.id,
                //     firstName: data.profile?.first_name,
                //     middleName: data.profile?.middle_name,
                //     lastName: data.profile?.last_name,
                //     gender: data.profile?.gender,
                //     phoneNumber: data.profile?.phone,
                //     birthdate: data.profile?.birthdate,
                //     code: data.profile?.code,
                //     email: data.profile?.email,
                //     street: data.profile?.address?.street,
                //     city: data.profile?.address?.city,
                //     state: data.profile?.address?.state,
                //     zip: data.profile?.address?.zip,
                //     country: data.profile?.address?.country,
                //     dateRegistered: data.created_at,

                //     resume: data.profile?.application?.resume,
                //     transcriptOfRecords: data.profile?.application?.transcript_of_record,
                //     license: data.profile?.application?.license,

                //     policeClearance: data.profile?.immigration?.police_clearance, 
                //     medicalClearance: data.profile?.immigration?.medical_clearance, 
                //     vaccinationCard: data.profile?.immigration?.vaccination_card,
                //     statementOfAccount: data.profile?.immigration?.statement_of_account,
                //     birthCertificate: data.profile?.immigration?.birth_certificate,
                //     passportPhoto: data.profile?.immigration?.passport_photo,
                //     idPicture: data.profile?.immigration?.id_picture,

                //     workPermit: data.profile?.immigration?.work_permit,
                //     familyInfo: data.profile?.immigration?.family_info,
                //     statutoryDeclaration: data.profile?.immigration?.statutory_declaration,
                //     useOfRepresentative: data.profile?.immigration?.use_of_representative,
                //     authReleasePersonalInfo: data.profile?.immigration?.auth_release_personal_info,

                //     howDidYouHeardAboutUs: data.profile?.application?.how_did_you_heard_about_us,
                //     desiredPosition: data.profile?.application?.desired_position,
                //     availableStartDate: data.profile?.application?.available_start_date,

                //     institution: data.profile?.education?.institution,
                //     diplomaCertificate: data.profile?.education?.diploma,
                //     diplomaFirstName: data.profile?.education?.first_name,
                //     diplomaMiddleName: data.profile?.education?.middle_name,
                //     diplomaLastName: data.profile?.education?.last_name,
                //     fieldOfStudy: data.profile?.education?.field_of_study,
                //     yearAwarded: data.profile?.education?.year_awarded,
                //     yearAwardedFrom: data.profile?.education?.year_awarded_from,
                //     yearAwardedTo: data.profile?.education?.year_awarded_to,
                //     trainingsCertificates: data.profile?.education?.trainings_or_certificates,


                //     ieltsTestType: data.profile?.ielts?.test_type,
                //     ieltsTestFormat: data.profile?.ielts?.test_format,
                //     ieltsTestDate: data.profile?.ielts?.test_date,
                //     ieltsTestReferenceId: data.profile?.ielts?.reference_id,
                //     ieltsListening: data.profile?.ielts?.listening_result,
                //     ieltsReading: data.profile?.ielts?.reading_result,
                //     ieltsWriting: data.profile?.ielts?.writing_result,
                //     ieltsSpeaking: data.profile?.ielts?.speaking_result,

                //     processingTime: data.profile?.processing_time,
                // } 
            }
        })
    },
    progressForward({ commit, dispatch }, applicant) {
        return Applicant.progressForward(applicant[key]).then(response => {
            const status = response.data.status

            switch (response.status) {
                case 202:
                    commit('CHANGE_STATUS', {
                        applicant,
                        status
                    })
                    dispatch('notifications/addSuccessNotification', AppString.applicantMoved + ` '${status}'`, { root: true })
                    break

                case 204:
                    dispatch('notifications/addWarningNotification', "Unable to progress applicant further", { root: true })
                    break
            }
        })
    },
    progressBackward({ commit, dispatch }, applicant) {
        return Applicant.progressBackward(applicant[key]).then(response => {
            const status = response.data.status

            switch (response.status) {
                case 202:
                    commit('CHANGE_STATUS', {
                        applicant,
                        status
                    })
                    dispatch('notifications/addSuccessNotification', AppString.applicantMoved + ` '${status}'`, { root: true })
                    break

                case 204:
                    dispatch('notifications/addWarningNotification', "Unable to revert applicant status further", { root: true })
                    break
            }
        })
    },
    searchByName({ dispatch }, payload) {
        return dispatch('fetchPagination', {
            'filter[or][][name][like]': payload,
            expand: 'profile'
        })
    },
}

export const mutations = {
    ...pagination.mutations,
    CHANGE_STATUS(state, { applicant, status }) {
        applicant.profile.status = status

        const app = state.all.find(item => item.id === applicant.id)
        app.profile.status = status
    },

    ADD_REGISTRANT(state, registrant) { state.all.unshift(registrant) },

    UPDATE_REGISTRANT(state, payload) {
        const registrant = state.all.find(registrant => 
            registrant[key].toLowerCase() === payload[key].toLowerCase())

        if (registrant)
            Object.assign(registrant, payload.registrant)
    },

    DELETE_REGISTRANT(state, registrant) {
        const index = state.all.findIndex(item => item === registrant)

        if (index >= 0) {
            state.all.splice(index, 1)
            // update pagination items length
            // --state.pagination.itemsLength
        }
    },

    // REVIEW: do we really need this?
    ARCHIVE_REGISTRANT(state, registrant) {
        const index = state.all.findIndex(item => item === registrant)

        if (index >= 0) {
            state.all.splice(index, 1)
            // update pagination items length
            // --state.pagination.itemsLength
        }
    },

    // REVIEW: do we really need this?
    DENY_REGISTRANT(state, { registrant, deniedAt }) {
        registrant.denied_at = deniedAt

        const app = state.all.find(item => item.id === registrant.id)
        app.denied_at = deniedAt
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
