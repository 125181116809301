<template>
  <InitialInterview
    v-bind="$props"
    v-on="$listeners"
    >

    <template #table.item.action="slotProps">
      <ProgressBackwardItemAction
        v-bind="slotProps"
        v-on="slotProps.on"
      />

      <ProgressForwardItemAction
        v-bind="slotProps"
        v-on="slotProps.on"
      />

    </template>
  </InitialInterview>
</template>

<script>
import InitialInterview from './InitialInterview'

export default {
  name: 'FinalInterview',
  extends: InitialInterview,

  components: {
      InitialInterview
  },

  props: { 
    resourcePath: {
      type: String,
      default: "finalInterviewees"
    },
    title: {
        type: String,
        default: "Final Interviewees"
    },
    subject: {
        type: String,
        default: "Final Interviewee"
    },
  },

}
</script>
