<template>
  <v-container class="pa-0">
    <v-row>
      <v-col xl="3">
        <v-card
          :height="cardHeight"
          :to="{ name: 'Applicants' }"
          min-width="380"
          color="blue darken-2"
        >
          <v-card-text class="white--text">
            <v-container>
              <v-row>
                <v-col sm="4">
                  <v-icon size="54" color="white">
                    {{ this.$icons.applicantsPortal }}
                  </v-icon>
                </v-col>

                <v-col sm="8">
                  <div class="text-overline">Total no. of Applicants</div>
                  <div class="text-h1">
                    {{ applicantsCount }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xl="3">
        <v-card
          min-width="380"
          :height="cardHeight"
          :to="{
            name: 'Applicants',
            params: { customSearch: 'submissions:filled status:new' },
          }"
          color="orange"
          dark
        >
          <v-card-text class="white--text">
            <v-container
              ><v-row>
                <v-col sm="4">
                  <v-icon size="54">
                    {{ this.$icons.applicantsPortal }}
                  </v-icon>
                </v-col>

                <v-col sm="8">
                  <div class="text-overline">Submitted Application Forms</div>
                  <div class="text-h1">
                    {{ completedCredentialApplicants }}
                  </div>
                </v-col>
              </v-row></v-container
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xl="3">
        <v-card
          min-width="380"
          :height="cardHeight"
          :to="{ name: 'InitialInterview' }"
          color="teal"
          dark
        >
          <v-card-text class="white--text">
            <v-container
              ><v-row>
                <v-col sm="4">
                  <v-icon size="54">
                    {{ this.$icons.interview }}
                  </v-icon>
                </v-col>

                <v-col sm="8">
                  <div class="text-overline">Interviewees</div>
                  <div class="text-h1">
                    {{ intervieweesCount }}
                  </div>
                </v-col>
              </v-row></v-container
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xl="3">
        <v-card
          min-width="380"
          :height="cardHeight"
          :to="{ name: 'Approved' }"
          color="purple lighten-1"
          dark
        >
          <v-card-text class="white--text">
            <v-container
              ><v-row>
                <v-col sm="4">
                  <v-icon size="54">
                    {{ this.$icons.approvedApplicants }}
                  </v-icon>
                </v-col>

                <v-col sm="8">
                  <div class="text-overline">Approved Applicants</div>
                  <div class="text-h1">
                    {{ approvedCount }}
                  </div>
                </v-col>
              </v-row></v-container
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xl="6">
        <v-card
          min-width="380"
          :height="cardHeight"
          :to="{ name: 'DomesticApplicants' }"
          color="red lighten-1"
          dark
        >
          <v-card-text class="white--text">
            <v-container
              ><v-row>
                <v-col sm="4">
                  <v-icon size="54"> mdi-leaf-maple </v-icon>
                </v-col>

                <v-col sm="8">
                  <div class="text-overline">Domestic</div>
                  <div class="text-h1">
                    {{ domesticCount }}
                  </div>
                </v-col>
              </v-row></v-container
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xl="6">
        <v-card
          min-width="380"
          :height="cardHeight"
          color="cyan lighten-1"
          :to="{
            name: 'Applicants',
            params: {
              customSearch: 'country:empty country!=canada',
            },
          }"
          dark
        >
          <v-card-text class="white--text">
            <v-container
              ><v-row>
                <v-col sm="4">
                  <v-icon size="54"> mdi-earth </v-icon>
                </v-col>

                <v-col sm="8">
                  <div class="text-overline">International</div>
                  <div class="text-h1">
                    {{ internationalCount }}
                  </div>
                </v-col>
              </v-row></v-container
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            New Registrants in the last {{ value.length }} Days
          </v-card-title>

          <v-sparkline
            v-model="value"
            :labels="labels"
            :gradient="['green', 'lightgreen', 'yellow', 'orange']"
            line-width="1"
            stroke-linecap="round"
            label-size="2"
            smooth="5"
            auto-draw
          >
          </v-sparkline>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row> -->
    <!--   <v-col> -->
    <!--     <v-card> -->
    <!--       <v-card-title> -->
    <!--         Interviewees in the last month -->
    <!--       </v-card-title> -->

    <!--       <v-sparkline -->
    <!--         v-model="intervieweesLastMonth" -->
    <!--         :labels="intervieweesLastMonthLabels" -->
    <!--         line-width="1" -->
    <!--         stroke-linecap="round" -->
    <!--         label-size="2" -->
    <!--         smooth="5" -->
    <!--         auto-draw -->
    <!--         > -->
    <!--       </v-sparkline> -->
    <!--     </v-card> -->
    <!--   </v-col> -->
    <!-- </v-row> -->
  </v-container>
</template>
<script>
// TODO: Refactor this mess of a code
export default {
  name: "Dashboard",
  data() {
    return {
      // sparkline options
      value: [],
      labels: [],

      intervieweesLastMonth: [],
      intervieweesLastMonthLabels: [],
      //

      cardHeight: 190,
      applicantsCount: 0,
      intervieweesCount: 0,
      approvedCount: 0,
      completedCredentialApplicants: 0,
      profileCompletedApplicants: 0,
      domesticCount: 0,
      internationalCount: 0,
    }
  },
  mounted() {
    this.$axios
      .get("dashboard/index")
      .then(({ data }) => {
        this.applicantsCount = data.no_of_applicants
        this.completedCredentialApplicants =
          data.no_of_applicants_completed_application
        this.intervieweesCount = data.interviews_count
        this.approvedCount = data.approved_count
        this.domesticCount = data.domestic
        this.internationalCount = data.international
        this.labels = data.sparkline.map((i) =>
          i.date.substr(-5).replace("-", "/")
        )
        this.value = data.sparkline.map((i) => parseInt(i.count))

        this.intervieweesLastMonthLabels = data.interviewees_lastmonth.map(
          (i) => i.date.substr(-5).replace("-", "/")
        )
        this.intervieweesLastMonth = data.interviewees_lastmonth.map((i) =>
          parseInt(i.count)
        )
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>
