import Api, { actions, mapMethods } from './api'

const ENDPOINT = 'all-applicants' // TEMP: 

export const key = 'id' 

export default {
    ENDPOINT,
    key,
    ...mapMethods(ENDPOINT, [
        'search',
        'delete',
        'update',
        'create',
    ]),
    download(params) {
        return Api.get(`${ENDPOINT}/download`, {
            responseType: 'blob',
            params
        })
    },
    view(id) {
        return actions(ENDPOINT).view(id, {
            expand: 'profile.rating,profile.education,profile.ielts,profile.application,profile.address,profile.immigration'
        
        })
    },
    progressBackward(id) {
        return Api.put(`${ENDPOINT}/${id}/progress-backward`)
    },
    progressForward(id) {
        return Api.put(`${ENDPOINT}/${id}/progress`)
    },
    archive(id) {
        return Api.put(`${ENDPOINT}/${id}/archive`)
    },
    deny(id, reason) {
        return Api.put(`${ENDPOINT}/${id}/deny`, { deny_reason: reason })
    },
}
