<template>
  <Applicants
    v-bind="$props"
    v-on="$listeners"
    title="Archived Applicants"
    subject="Archived Applicant"
    hide-append-buttons
    :csv-downloadable="false"
    >

    <template #table.item.action="slotProps">
      <UnarchiveItemAction
        v-bind="slotProps"
        v-on="{
            'click:unarchive-item': onUnarchiveItem
        }"
      />
    </template>
  </Applicants>
</template>

<script>
import Applicants from './Applicants'
import  { UnarchiveItemAction } from './base/table'

export default {
  name: 'ArchivedApplicants',
  components: {
    Applicants,
    UnarchiveItemAction
  },
  props: { 
    resourcePath: {
      type: String,
      default: "archivedApplicants"
    },
    headers: {
      type: Array,
      default: function () {
        return [
          {
            text: 'Id',
            value: this.$config('applicant.id'),
          },
          {
            text: 'Name',
            value: 'name',
            customValue: function (item) {
              return `${item.profile.last_name}, ${item.profile.first_name}`
            },
          },
          {
            text: 'Email',
            value: 'email'
          },
          {
            text: 'Phone',
            value: 'profile.phone',
            sortable: false
          },
          {
            text: 'Status',
            value: 'profile.status',
            align: 'center'
          },
          {
              text: 'Date Archived',
              value: 'archived_at',
              align: 'center',
              filterable: false
          },
          {
            text: 'Action',
            value: 'action',
            align: 'center',
            sortable: false,
            filterable: false
          }
        ]
      }
    }
  },
  methods: {
      onUnarchiveItem(item) {
          const event = 'click:unarchive-item'

          if (event in this.$listeners) {
            this.$emit(event, item)

            return
          }

          this.$store.dispatch('prompt/confirm', {
            title: 'Restore archived',
            data: item
          }).then(response => {
            if (response) {
              this.$store.dispatch(`${this.resourcePath}/restore`, item)
            }
          })
      }
  }
}

</script>

