<template>
  <AppContent
    v-bind="$props"
    v-on="$listeners"
    :table-props="{
      headers
    }"
    title="Payments"
    subject="Payment"
    :form-dialog-props="{ maxWidth: 660 }"
  >
    <template #form="slotProps">
      <PaymentForm
        v-bind="slotProps"
      />
    </template>
  </AppContent>
</template>

<script>
// TODO: file validation 
import AppContent from './AppContent'
import PaymentForm from './payment/PaymentForm'
import moment from 'moment'

export default {
  name: 'Payments',
  components: {
    AppContent,
    PaymentForm
  },

  props: {
    resourcePath: {
      type: String,
      default: 'payments'
    },
    headers: {
      type: Array,
      default: function () {
        return [
          {
            text: 'Id',
            value: 'id'
          },
          {
            text: 'Payor Name',
            value: 'payor_name'
          },
          {
            text: 'Product',
            value: 'product'
          },
          {
            text: 'Description',
            value: 'description'
          },
          {
            text: 'Payment Type',
            value: 'type'
          },
          {
            text: 'Amount',
            value: 'amount',
            align: 'right',
            filterable: false,
            customValue: (item) => {
              return (new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })).format(item.amount)
            },
          },
          {
            text: 'Medium',
            value: 'medium'
          },
          {
            text: 'Paid Date',
            value: 'paid_datetime',
            customValue: (item) => {
              return moment.parseZone(item.paid_datetime).format("LLLL (UTC Z)")
            }
          },
          {
            text: 'Actions',
            value: 'action',
            align: 'center',
            sortable: false,
            filterable: false
          }
        ]
      }
    }
  }
}
</script>

