<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-toolbar>
        <v-text-field
          v-model="search"
          placeholder="Search name"
          hide-details="auto"
          clearable
          @click:clear="search = ''"
          :prepend-icon="$icons.search"
          />
      </v-toolbar>
    </v-col>

    <v-col
      v-for="queue in filteredQueues"
      :key="queue.step"
      cols="12"
      md="3"
      style="min-width:400px; max-width: 400px"
      >

      <v-card>
        <v-card-title>{{ queue.progress_title }} ({{ queue.queue.length }})</v-card-title>

        <v-card-text>
          <v-list
            style="max-height: 600px"
            class="overflow-y-auto"
            dense>
            <v-list-item-group>
              <v-list-item
                v-for="item in queue.queue"
                :key="item.id"
                class="pb-2"
                @click="view(item)"
                >
                <v-list-item-icon>
                  <v-chip
                    dark
                    color="blue"
                  >
                  {{ item.number }}
                  </v-chip>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>

    <AppFormDialog
      ref="formDialog"
      v-bind="formDialogSlotProps.attrs"
      v-on="formDialogSlotProps.on"
      >

      <template #form="slotProps">
        <v-tabs
          v-model="tab"
          active-class="white black--text"
          show-arrows
          grow
          center-active
        >
          <v-tab
            v-for="tab in tabItems"
            :key="tab.name"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items 
           v-model="tab"
        >

          <v-tab-item v-for="tab in tabItems"
            :key="tab.name">
            <v-card
              flat>
              <v-card-text>
                <component 
                  :is="tab.component"
                  v-bind="slotProps"
                >
                </component>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </template>

    </AppFormDialog>
  </v-row>
</template>

<script>
import Queue from '@/api/queue'
import AppFormDialog, { Mode } from './AppFormDialog'
import ProfileForm from './applicant/ProfileForm'
import EducationForm from './applicant/EducationForm'
import IELTSForm from './applicant/IELTSForm'
import ApplicationForm from './applicant/ApplicationForm'
import ImmigrationForm from './applicant/ImmigrationForm'

import { AppString } from '../strings'
import lodash from 'lodash'

export default {
  name: 'Queue',

  components: {
    AppFormDialog
  },

  data() {
    return {
      loading: false,
      tab: null,
      tabItems: [
        {
          name: 'Personal',
          component: ProfileForm
        },
        {
          name: 'Education',
          component: EducationForm
        },
        {
          name: 'IELTS',
          component: IELTSForm
        },
        {
          name: 'Application',
          component: ApplicationForm
        },
        {
          name: 'Immigration',
          component: ImmigrationForm
        },
      ],
      queues: [],
      formDialogPropsValue: {
        item: {},
        editedItem: null,
        mode: 0,
        value: false
      },
      search: '',
    }
  },

  computed: {
    formDialogSlotProps() {
      return {
        attrs: { 
          subject: "Applicant",
          loading: this.loading,
          maxWidth: 700,
          ...this.formDialogPropsValue,
        },
        on: {
            close: () => {
                this.formDialogPropsValue.value = false
                this.formDialogPropsValue.mode = 0
            }
        }
      }
    },

    filteredQueues() {
      const search = this.search ?? ''

      return this.queues.map(queue => 
        ({
          ...queue,
          progress_title: lodash.startCase(lodash.toLower(queue.progress_title)),
          queue: queue.queue.filter(({ name, number }) => 
            name.toLowerCase().indexOf(search.toLowerCase()) >= 0
            || number == this.search
          )
        })
      )
    }
  },

  methods: {
    view(item) {
      this.fetchData(item).then(data => {
        if (!data) return
        this.formDialogPropsValue.item = data
        this.formDialogPropsValue.editedItem = null
        this.formDialogPropsValue.mode = Mode.VIEW
        this.formDialogPropsValue.value = true
      })
    },

    async fetchData(item) {
      let data = await this.$store.dispatch(`applicants/view`, item).catch(error => {
          console.log(error)
          this.$store.dispatch('notifications/addWarningNotification', AppString.serverError)
          })
      return data
    } 
  },

  created() {
    Queue.search().then(response => {
      this.queues = response.data
    })

    /* Queue.enqueue('initial-interview', 395).then(response => { */
    /*   console.log(response) */
    /* }) */

    /* Queue.dequeue('registrants').then(response => { */
    /*   console.log(response) */
    /* }) */
  }
}
</script>

