<template>
  <AppForm v-bind="$props" v-on="$listeners">
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          :value="item.profile.first_name"
          label="First Name"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="4">
        <v-text-field
          :value="item.profile.middle_name"
          label="Middle Name"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="4">
        <v-text-field
          :value="item.profile.last_name"
          label="Last Name"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="2">
        <v-text-field
          :value="item.profile.gender"
          label="Gender"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          :value="item.profile.phone"
          label="Phone Number"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          :value="item.email"
          label="E-mail"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8">
        <v-text-field
          :value="item.profile?.address?.street"
          label="Street/Address"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          :value="item.profile?.address?.city"
          label="City/Town"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          :value="item.profile?.address?.state"
          label="State/Province"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          :value="item.profile?.address?.zip"
          label="Zip Code"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          :value="item.profile?.address?.country"
          :items="countries"
          item-text="name"
          label="Country"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <v-menu v-model="dateMenu" min-width="auto" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="item.profile.birthdate"
              prepend-inner-icon="mdi-calendar"
              label="Date of Birth"
              v-bind="attrs"
              v-on="on"
              readonly
              hide-details="auto"
              :persistent-placeholder="true"
            >
            </v-text-field>
          </template>
          <v-date-picker
            :value="item.profile.birthdate"
            @input="dateMenu = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          :value="item.profile.code"
          label="Access code"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>

      <v-col>
        <v-chip text-color="white" color="light-blue" label>
          <v-icon left> mdi-calendar-star </v-icon>
          Date registered: {{ formatDate(item.created_at) }}
        </v-chip>
      </v-col>

      <v-spacer></v-spacer>

      <v-col>
        <v-chip text-color="white" color="deep-orange lighten-1" label>
          <v-icon left> mdi-progress-clock </v-icon>
          Processing Time: {{ item.profile.processing_time }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <span class="title"> Downloadables</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="({ label, path: value }, fileType) in uploadableItems"
        :key="fileType"
        cols="12"
        sm="6"
      >
        <v-item-group class="v-btn-toggle">
          <v-badge
            v-if="value"
            overlap
            left
            tile
            :content="value && value.split('.').pop()"
          >
            <v-btn
              @click.stop="show(value)"
              :disabled="!(!!value && isReadableFile(value))"
              width="200px"
            >
              {{ label }}
            </v-btn>
          </v-badge>

          <v-btn
            v-else
            :disabled="!(!!value && isReadableFile(value))"
            width="200px"
          >
            {{ label }}
          </v-btn>

          <v-btn :disabled="!!!value" @click.stop="download(value, label)">
            <v-icon>mdi-download</v-icon>
          </v-btn>

          <v-btn @click.stop="upload(value, label, fileType)">
            <v-icon>mdi-upload</v-icon>
          </v-btn>
        </v-item-group>
      </v-col>

      <v-col>
        <viewer> </viewer>
      </v-col>

      <v-col>
        <v-dialog v-model="showPdfViewer" scrollable max-width="1000px">
          <v-card>
            <v-card-text color="gray">
              <v-container>
                <v-row>
                  <v-col>
                    <vue-pdf-embed :source="source1" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer />
              <v-btn @click.stop="showPdfViewer = false"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <FileUploadDialog
      v-model="fileUploadDialog"
      :title="fileUploadTitle"
      :loading="loading"
      accept="image/png,image/jpeg,image/jpg,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      width="400"
      :error-messages="uploadError"
      @click:upload="uploadFile(item, $event)"
      @click:close="fileUploadDialog = false"
    >
    </FileUploadDialog>
  </AppForm>
</template>

<script>
import api from "@/api/api"
import AppForm from "../AppForm"
import FileUploadDialog from "../base/FileUploadDialog"
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed"
import { getErrorMessagePair } from "@/util/errorResponseUtil"

import moment from "moment"

export default {
  name: "ProfileForm",
  extends: AppForm,
  components: {
    AppForm,
    VuePdfEmbed,
    FileUploadDialog,
  },

  data() {
    return {
      uploadError: {},
      loading: false,
      fileUploadDialog: false,
      dateMenu: false,
      showPdfViewer: false,
      countries: [],
      source1: "",
      fileUploadTitle: "",
      fileUploadType: "",

      /* downloadableItemKeys: { */
      /*   resume: 'Resume', */
      /*   license: 'License', */
      /*   transcriptOfRecords: 'Transcript', */
      /*   policeClearance: 'Police Clearance', */
      /*   medicalClearance: 'Medical Clearance', */
      /*   vaccinationCard: 'Vaccination Card', */
      /*   statementOfAccount: 'SOA', */
      /*   birthCertificate: 'Birth Certificate', */
      /*   passportPhoto: 'Passport Photo', */
      /*   idPicture: 'Id Picture', */
      /* } */
    }
  },

  /* props: { */
  /*     downloadableItemKeys: { */
  /*       type: Object, */
  /*       default: function() { */
  /*           return {} */
  /*         } */
  /*     } */
  /* }, */

  computed: {
    uploadableItems() {
      const { resume, transcript_of_record, license } =
        this.item.profile?.application ?? {}

      const {
        police_clearance,
        medical_clearance,
        vaccination_card,
        statement_of_account,
        birth_certificate,
        passport_photo,
        visa_application_photo,
        id_picture,
        marriage_license,
        financial_means,
      } = this.item.profile?.immigration ?? {}

      const data = {
        "application.resume": { path: resume, label: "Resume" },
        "application.license": { path: license, label: "License" },
        "application.transcript_of_record": {
          path: transcript_of_record,
          label: "Transcript",
        },
        "immigration.police_clearance": {
          path: police_clearance,
          label: "Police Clearance",
        },
        "immigration.medical_clearance": {
          path: medical_clearance,
          label: "Medical Clearance",
        },
        "immigration.vaccination_card": {
          path: vaccination_card,
          label: "Vaccination Card",
        },
        "immigration.statement_of_account": {
          path: statement_of_account,
          label: "SOA",
        },
        "immigration.birth_certificate": {
          path: birth_certificate,
          label: "Birth Certificate",
        },
        "immigration.passport_photo": {
          path: passport_photo,
          label: "Passport Photo",
        },
        "immigration.visa_application_photo": {
          path: visa_application_photo,
          label: "Visa Application Photo",
        },
        "immigration.id_picture": {
          path: id_picture,
          label: "Id Picture",
        },
        "immigration.marriage_license": {
          path: marriage_license,
          label: "Marriage License",
        },
        "immigration.financial_means": {
          path: financial_means,
          label: "Financial Means",
        },
      }
      /* const data = [ */
      /*   ["resume", resume, "Resume"], */
      /*   ["license", license, "License"], */
      /*   ["transcript_of_record", transcript_of_record, "Transcript"], */
      /*   ["police_clearance", police_clearance, "Police Clearance"], */
      /*   ["medical_clearance", medical_clearance, "Medical Clearance"], */
      /*   ["vaccination_card", vaccination_card, "Vaccination Card"], */
      /*   ["statement_of_account", statement_of_account, "SOA"], */
      /*   ["birth_certificate", birth_certificate, "Birth Certificate"], */
      /*   ["passport_photo", passport_photo, "Passport Photo"], */
      /*   ["visa_application_photo", visa_application_photo, "Visa Application Photo"], */
      /*   ["id_picture", id_picture, "Id Picture"], */
      /* ] */

      /* console.log(data) */

      return data
    },
    downloadableItems() {
      /* return Object.entries(this.downloadableItemKeys) */
      /*   .filter(([key]) => this.item[key]) */
      const { resume, transcript_of_record, license } =
        this.item.profile?.application ?? {}

      const {
        police_clearance,
        medical_clearance,
        vaccination_card,
        statement_of_account,
        birth_certificate,
        passport_photo,
        visa_application_photo,
        id_picture,
        marriage_license,
        financial_means,
      } = this.item.profile?.immigration ?? {}

      const data = [
        ["Resume", resume],
        ["License", license],
        ["Transcript", transcript_of_record],
        ["Police Clearance", police_clearance],
        ["Medical Clearance", medical_clearance],
        ["Vaccination Card", vaccination_card],
        ["SOA", statement_of_account],
        ["Birth Certificate", birth_certificate],
        ["Passport Photo", passport_photo],
        ["Visa Application Photo", visa_application_photo],
        ["Id Picture", id_picture],
        ["Marriage License", marriage_license],
        ["Financial Means", financial_means],
      ].filter(([, value]) => value)
      console.log(data)

      return data
    },

    hasDownloadables() {
      return this.downloadableItems.length > 0
    },
  },

  created() {
    // TODO: move @store/modules to prevent rerequest every time we view applicant resource
    api.get("countries").then((response) => {
      this.countries = response.data
    })
  },

  methods: {
    uploadFile(item, file) {
      const [fileKey, fileType] = this.fileUploadType.split(".")

      const data = new FormData()
      data.append("type", fileType)
      data.append("file", file)
      console.log(data)

      this.loading = true
      this.$axios
        .post(`all-applicants/${item.id}/files`, data)
        .then((response) => {
          console.log(response)
          this.$store.dispatch(
            "notifications/addSuccessNotification",
            "Uploaded Successfully."
          )

          console.log(fileKey, fileType)

          /* this.item.profile[fileKey][fileType]= response.data.path */
          this.item.profile = Object.assign(this.item.profile, {
            [fileKey]: {
              [fileType]: response.data.path,
            },
          })
          this.fileUploadDialog = false
        })
        .catch((error) => {
          if (error?.response?.status) {
            if (error.response.status === 500) {
              this.$store.dispatch(
                "notifications/addWarningNotification",
                "Server Error"
              )
              return
            }

            if (error.response.status === 422) {
              this.uploadError = getErrorMessagePair(error.response.data)
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(value) {
      return moment.unix(value).format("MMM. D, YYYY")
    },
    upload(fileUrl, label, fileType = "") {
      console.log(fileUrl, label)
      this.fileUploadDialog = true
      this.uploadError = {}
      this.fileUploadType = fileType
      this.fileUploadTitle = "Upload " + label
    },
    download(fileUrl, label) {
      console.log(fileUrl, label)
      this.$axios({
        url: fileUrl,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/octet-stream" })
          )
          const extension = fileUrl.split(".").pop()
          const link = document.createElement("a")
          const filename = label + "." + extension

          link.href = url
          link.download = filename

          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addWarningNotification",
            "Server error. Unable to fetch file."
          )
        })
    },
    getExtensionIfNotImage(file) {
      const extension = this.getFileExtension(file)
      return this.isReadableFile(file) ? "" : `(.${extension})`
    },

    getFileExtension(file) {
      return file.split(".").pop()
    },

    isReadableFile(file) {
      const extension = this.getFileExtension(file).toLowerCase()
      const imageExtensions = ["jpeg", "jpg", "png", "gif", "pdf"]

      return imageExtensions.includes(extension)
    },
    show(file) {
      this.$axios
        .get(file, {
          responseType: "blob",
        })
        .then((response) => {
          const extension = file.split(".").pop()

          const validImgExtensions = ["jpg", "jpeg", "png", "gif"]

          if (validImgExtensions.includes(extension.toLowerCase())) {
            const pic = URL.createObjectURL(response.data)
            this.$viewerApi({
              options: {
                focus: false,
              },
              images: [pic],
            })
          } else if (extension.toLowerCase() === "pdf") {
            this.source1 = URL.createObjectURL(response.data)
            this.showPdfViewer = true
          } else {
            console.log(`${extension} cannot be read.`)
          }
        })
        .catch(console.error)
    },
  },
}
</script>
