<template>
  <Applicants
    v-bind="$props"
    v-on="$listeners"
    title="Domestic Applicants"
    subject="Domestic Applicant"
    >
  </Applicants>
</template>

<script>
import Applicants from './Applicants'
import moment from 'moment'

export default {
  name: 'DomesticApplicants',
  components: {
    Applicants,
  },
  props: { 
    resourcePath: {
      type: String,
      default: "domestic"
    },
    headers: {
      type: Array,
      default: function() {
        return [
          {
            text: 'Id',
            value: this.$config('applicant.id'),
          },
          {
            text: 'Name',
            value: 'name',
            customValue: function (item) {
              return `${item.profile.last_name}, ${item.profile.first_name}`
            },
          },
          {
            text: 'Email',
            value: 'email'
          },
          {
            text: 'Date Registered',
            value: 'created_at',
            customValue: function (item) {
              return moment.unix(item.created_at).format('MMM. D, YYYY')
            }
          },
          {
            text: 'Country',
            value: 'profile.address.country',
          },
          {
            text: 'Code',
            value: 'profile.code',
            align: 'center',
          },
          {
            text: 'Desired Position',
            value: 'profile.application.desired_position',
            align: 'center',
          },
          {
            text: 'Referrer',
            value: 'profile.referrer',
            align: 'center',
          },
          {
            text: 'Submission(s)',
            value: 'submissions',
            align: 'center',
            filterable: false
          },
          {
            text: 'Status',
            value: 'profile.status',
            align: 'center'
          },
          {
            text: 'Action',
            value: 'action',
            align: 'center',
            filterable: false
          }
        ]
      }
    }
  },
}

</script>

