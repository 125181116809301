<template>
  <v-row>
    <v-col>
      <slot
        name="formDialog"
        v-bind="formDialogSlotProps"
      >

        <AppFormDialog
          v-bind="formDialogSlotProps.attrs"
          v-on="formDialogSlotProps.on"
        >

          <template #form="slotProps">
            <slot
              name="form"
              v-bind="slotProps"
              v-on="slotProps.on"
              :error-messages="errorMessages"
            />
          </template>


        </AppFormDialog>
      </slot>

      <v-row v-if="!hideCreateButton">
          <v-fab-transition>
              <v-btn
                      color="green"
                      @click.stop="onCreate"
                      bottom
                      dark
                      fab
                      fixed
                      large
                      right
                      >
                      <v-icon>mdi-plus</v-icon>
              </v-btn>
          </v-fab-transition>
      </v-row>

      <v-row>
        <v-col
          md="12">

          <slot 
            name="table"
            v-bind="tableSlotProps"
          >
            <AppTable
              v-bind="tableSlotProps.attrs"
              v-on="tableSlotProps.on"
            />
          </slot>

        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AppTable from './AppTable'
import AppFormDialog, { Mode } from './AppFormDialog'

import { AppString } from '../strings'
import { getErrorMessagePair } from '../util/errorResponseUtil'

export default {
  name: 'AppContent',
  components: {
    AppTable,
    AppFormDialog,
  },

  props: {
    resourcePath: {
      type: String,
      required: true
    },
    title: String,
    subject: String,
    hideCreateButton: {
        type: Boolean,
        default: false
    },

    formDialogProps: {
      type: Object,
      default: function() {
        return {
          maxWidth: 500
        }
      }
    },

    tableProps: {
      type: Object,
      default: function() {
        return {
          downloadable: true,
          headers: []
        }
      }
    }
  },

  data() {
    return {
      showFormDialog: false,
      errorMessages: {},
      tableSlotProps: {
        attrs: { 
          resourcePath: this.resourcePath,
          itemKey: 'id',
          title: this.title,
          ...this.tableProps
        },
        on: {
          'click:edit-item': this.onTableItemEdit,
          'click:view-item': this.onTableItemView,
          'click:delete-item': this.onTableItemDelete
        },
      },
      loading: false,

      formDialogPropsValue: {
        item: {},
        editedItem: null,
        mode: 0,
        value: false
      },
    }
  },

  computed: {
    formDialogSlotProps() {
      return {
        attrs: { 
          subject: this.subject,
          loading: this.loading,
          ...this.formDialogProps,
          ...this.formDialogPropsValue
        },
        on: {
          save: this.onSave,
          clear: this.onFormDialogClear,
          close: () => {
              this.formDialogPropsValue.value = false
              this.formDialogPropsValue.mode = 0
              this.clearErrorMessages()
          }
        }
      }
    },
  },

  methods: {
    onFormDialogClose() {
        this.showFormDialog = false
    },

    onFormDialogClear() {
        this.formDialogPropsValue.item = {}
    },

    onTableItemDelete(item) {
      const event = 'table-item-delete'
      
      if (event in this.$listeners) {
        this.$emit(event, item)

        return
      }

      this.$store.dispatch('prompt/confirm', {
        title: `Delete confirmation`,
        message: 'Are you sure you want to delete?'
      }).then(response => {
        if (response) 
          this.$store.dispatch(`${this.resourcePath}/delete`, item)
      })
    },

    onTableItemView(item) {
      const event = 'view'

      if (event in this.$listeners)
        this.$emit(event, item)
      else
        this.view(item)
    },

    clearErrorMessages() {
      this.errorMessages = {}
      this.$emit('error-response', this.errorMessages)
    },

    view(item) {
      this.fetchData(item).then(data => {
        this.formDialogPropsValue.item = data
        this.formDialogPropsValue.editedItem = null
        this.formDialogPropsValue.mode = Mode.VIEW
        this.formDialogPropsValue.value = true

        this.clearErrorMessages()
      })
    },

    async fetchData(item) {
       let data = await this.$store.dispatch(`${this.resourcePath}/view`, item).catch(error => {
           console.log(error)
           this.$store.dispatch('notifications/addWarningNotification', AppString.serverError)
       })
       return data
    },

    onTableItemEdit(item) {
      const event = 'edit'

      if (event in this.$listeners)
        this.$emit(event, item)
      else 
        this.edit(item)
    },

    edit(item) {
      this.fetchData(item).then(data => {
        this.formDialogPropsValue.item = data
        this.formDialogPropsValue.editedItem = Object.assign({}, this.formDialogPropsValue.item)
        this.formDialogPropsValue.mode = Mode.EDIT
        this.formDialogPropsValue.value = true

        this.clearErrorMessages()
      })
    },

    onCreate() {
      this.formDialogPropsValue.item = {}
      this.formDialogPropsValue.editedItem = null
      this.formDialogPropsValue.mode = Mode.CREATE
      this.formDialogPropsValue.value = true

      this.clearErrorMessages()
    },

    onSave(...args) {
      const event = 'save'

      if (event in this.$listeners)
        this.$emit(event, ...args)
      else 
        this.save(...args)
    },

    save(item, editedItem) {
      const params = { ...item }

      this.errorMessages = {}
      /* this.$refs.form.validate() */
      this.loading = true

      let request
      const { itemKey } = this.tableSlotProps.attrs

      request = (editedItem && item !== editedItem)
        ? this.$store.dispatch(`${this.resourcePath}/update`, { [`${itemKey}`]: editedItem[`${itemKey}`], params })
        : this.$store.dispatch(`${this.resourcePath}/save`, params)

      request.then(() => {
        this.formDialogPropsValue.value = false
      }).catch(error => {
        if (error.response.status !== 422) 
            this.$store.dispatch('notifications/addWarningNotification', AppString.serverError)


        this.errorMessages = getErrorMessagePair(error.response.data)
        this.$emit('error-response', this.errorMessages)
        console.log(this.errorMessages)
      }).finally(() => {
        this.loading = false
      })

    }
  }
}
</script>
