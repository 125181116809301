<template>
    <AppForm
        v-bind="$props"
        v-on="$listeners">
        <v-row>
            <v-col>
                <v-data-table
                    :items="jobsOffered"
                    :headers="jobOfferHeaders">
                    <template #item.created_at="{ value }">
                       {{ formatDate(value) }} 
                    </template>

                    <template #item.action="slotProps">
                        <DeleteItemAction v-bind="slotProps" v-on="slotProps.on" @click:delete-item="onDelete" />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                md="9">
                <v-autocomplete
                    label="Offer Job"
                    v-model="jobOfferSelected"
                    :items="availableJobOffers"
                    hide-selected
                    persistent-placeholder
                    hide-details="auto"
                    clearable>
                </v-autocomplete>
            </v-col>
            <v-col
             cols="12"
             md="3">
                <v-btn color="green" class="mt-3 white--text" :disabled="typeof jobOfferSelected === 'undefined' || jobOfferSelected === null"
                 @click="sendOffer">
                <v-icon left size="24">mdi-offer</v-icon>
                Send Offer
                </v-btn>
                    
            </v-col>
        </v-row>
    </AppForm>
</template>

<script>
import AppForm from '../AppForm'
import DeleteItemAction from '../base/table/DeleteItemAction'

import moment from 'moment'

export default {
    name: 'JobOfferForm',

    components: {
        AppForm,
        DeleteItemAction
    },

    props: {
        item: Object
    },

    data() {
        return {
            jobOfferSelected: null,
          jobOfferHeaders: [
            {
              text: 'Date Offered',
              value: 'created_at'
            },
            {
              text: 'Company',
              value: 'job.employer.company',
            },
            {
              text: 'Contact #',
              value: 'job.employer.contact_number',
            },
            {
              text: 'Job Title',
              value: 'job.title'
            },
            {
              text: 'Action',
              value: 'action',
              align: 'center',
              sortable: false
            }
          ]
        }
    },

    computed: {
        jobs() {
            return this.$store.getters['jobs/getAll'].filter(job =>  
                job.viewable && !(this.jobsOffered.filter(offeredJob => offeredJob.job_id === job.id).length > 0)
            )
        },

        jobsOffered() {
            return this.$store.getters['jobOffers/getAll']
        },
        
        availableJobOffers() {
            return this.jobs.map(item => ({
                text: item.title,
                value: item.id
            }))
        }
    },

    methods: {
        formatDate(unix) {
            return moment.unix(unix).format('MMM. D, YYYY')
        },
        sendOffer() {
          this.$store.dispatch('prompt/confirm', {
            title: `Job offer confirmation`,
            message: 'Are you sure you want to offer this job?'
          }).then(response => {
            if (response) {
                this.$store.dispatch('jobOffers/save', {
                   profile_id: this.item.profile.id,
                   job_id: this.jobOfferSelected
                }).catch(error => {
                    console.log(error)
                   this.$store.dispatch('notifications/addWarningNotification', 'Job offer failed')
               })
            }
          })
        },

        onDelete(item) {
          this.$store.dispatch('prompt/confirm', {
            title: `Revoke offer confirmation`,
            message: 'Are you sure you want to revoke this offer?'
          }).then(response => {
            if (response)
              this.$store.dispatch(`jobOffers/delete`, item)
          })
        }
    },

    mounted() {
        this.$store.dispatch('jobs/fetchPagination')
    }
}
</script>
