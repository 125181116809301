<template>
  <AppForm v-bind="$props" v-on="$listeners">
    <v-row>
      <v-col>
        <v-data-table :items="interviews" :headers="interviewScheduleHeaders" hide-default-footer>
          <template #item.start_datetime="{ value }">
            {{ formatDate(value, 'MMM-DD-YYYY hh:mma') }}
          </template>

          <template #item.duration="{ value }">
            {{ durationToHours(value) }}
          </template>

          <template #item.type="{ value }">
            <v-chip small text-color="white" :color="value.toLowerCase().indexOf('initial') ? 'purple': 'purple lighten-3'">{{ value?.split(' ').shift() }}</v-chip>
          </template>

          <template #item.average_rating="slotProps">
              <div v-if="slotProps.item.ratings > 0"
                    class="green--text text--darken-3">
                    <!--<span>{{ slotProps.value }}</span> out of 5-->
                    <v-rating class="rating-item-label" 
                        color="yellow darken-2"
                        readonly 
                        small 
                        :value="slotProps.value"
                        half-increments>
                    </v-rating>
                  <h5>{{ slotProps.item.ratings }} rating(s)</h5>
              </div>

              <div v-else>
                <v-chip color="orange" text-color="white" small>unrated</v-chip>
              </div>
          </template>

          <template #item.action="slotProps">
            <v-btn
            v-if="slotProps.item.status && slotProps.item.status.toLowerCase() === 'passed'"
            icon
            plain
            @click="onViewRate(slotProps.item)">
                <v-icon color="yellow darken-2">mdi-seal</v-icon>
            </v-btn>

            <EditItemAction v-bind="slotProps" v-on="slotProps.on" @click:edit-item="onEdit" />

            <DeleteItemAction v-bind="slotProps" v-on="slotProps.on" @click:delete-item="onDelete" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!--<v-row v-if="!hasPassedFinalInterview">-->
    <v-row 
        justify="space-between">
      <v-col
        v-if="currentApplicant?.profile?.status.toLowerCase().indexOf('interview') > -1"
      >
        <v-tooltip 
            top 
            color="orange"
            :disabled="hasPassedFinalInterview">
            <template #activator="{ on }">
                <!-- SEE: https://stackoverflow.com/questions/56370021/display-vuetify-tooltip-on-disabled-button -->
                <div 
                    v-on="on"
                    class="d-inline-block"
                    >
                    <v-btn 
                        color="green" 
                        class="white--text"
                        :disabled="!hasPassedFinalInterview"
                        @click="approveApplicant"
                        >
                      <v-icon left>mdi-thumb-up</v-icon>
                      Approve
                    </v-btn>
                </div>
            </template>
            <span>
                Must have "passed" the final interview
            </span>
        </v-tooltip>
      </v-col>

      <v-col v-if="!hasPassedFinalInterview">
        <v-tooltip top :disabled="forInterview">
            <template #activator="{ on }">
                <div 
                    v-on="on"
                    class="d-inline-block float-sm-right"
                    >
                    <v-btn 
                        :disabled="!forInterview"
                        color="primary" @click="scheduleInterview">
                      <v-icon left>mdi-plus</v-icon>
                      Schedule Interview
                    </v-btn>
                </div>
            </template>
            <span>
                Applicant status must be "for initial interview"
            </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-divider></v-divider>
        </v-col>
    </v-row>

    <v-row justify="space-between">
      <v-col>
        <v-chip text-color="white" color="red" label>
          <v-icon left small>mdi-call-missed</v-icon>
          Missed Initial Interviews:
          <v-avatar right color="red darken-4">{{ missedInitialInterviews }}</v-avatar>
        </v-chip>
      </v-col>

      <v-col>
        <v-chip class="float-sm-right" text-color="white" color="red" label>
          <v-icon left small>mdi-phone-missed</v-icon>
          Missed Final Interviews:
          <v-avatar right color="red darken-4">{{ missedFinalInterviews }}</v-avatar>
        </v-chip>
      </v-col>
    </v-row>


    <v-row
        v-if="false">
      <v-col>
        <v-btn>
        Rate
        </v-btn>
        <v-expansion-panels
            >
            <v-expansion-panel>
                <v-expansion-panel-header>
                      Interview:
                      <!--<v-rating 
                        v-model="rating"
                        color="yellow" 
                        background-color="yellow" 
                        half-increments
                        readonly class="my-n16"
                        >
                      </v-rating>-->
                </v-expansion-panel-header>
                    
                <v-expansion-panel-content>
                hello
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <AppFormDialog 
        v-model="rateDialog"
        subject="Rate Applicant Interview"
        max-width="330"
        >
            <v-card dense>
                <v-toolbar color="teal darken-2" dark>
                  <v-toolbar-title>
                    <v-icon color="yellow" size="30">mdi-seal</v-icon> Rate Applicant Interview</v-toolbar-title>
                </v-toolbar>

                <v-card-text
                    class="text-center pt-5">
                    <div
                        class="text-h5 yellow--text text--darken-3">
                        <span class="text-h4">{{ rating }}</span> out of 5
                    </div>
                    <v-rating
                        v-model="rating"
                        color="yellow"
                        background-color="yellow"
                        half-increments
                        large>
                    </v-rating>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="submitRate(interview, rating)" text>
                    Rate
                  </v-btn>
                  <v-btn @click="rateDialog = false" color="red" text>
                    Close
                  </v-btn>
                </v-card-actions>
          </v-card>

    </AppFormDialog>

    <AppFormDialog 
        v-model="interviewDialog" 
        subject="Schedule Interview" 
        max-width="600"
    >
      <v-card>
        <v-toolbar color="teal darken-2" dark>
          <v-toolbar-title>Schedule Interview</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col>
                  <v-combobox v-model="interview.interviewers" :items="interviewersSelection"
                    :error-messages="errorMessages.interviewers" item-text="fullName" item-value="email"
                    hide-details="auto" label="Interviewers" prepend-icon="mdi-filter-variant" :return-object="false"
                    hide-selected chips clearable multiple persistent-placeholder>
                    <template #item="{ item }">
                      <div v-if="item.email">
                        <strong>{{ item.fullName }}</strong>&nbsp;
                        <span>&lt;{{ item.email }}&gt;</span>
                      </div>
                      <div v-else>
                        {{ item }}
                      </div>
                    </template>

                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip v-bind="attrs" :input-value="selected" close small @click:close="parent.selectItem(item)">
                        <div v-if="item.email">
                          <strong>{{ item.fullName }}</strong>&nbsp;
                          <span>&lt;{{ item.email }}&gt;</span>
                        </div>
                        <div v-else>
                          {{ item }}
                        </div>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">

                  <v-menu v-model="dateMenu" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="formatDate(interview.date)" prepend-icon="mdi-calendar"
                        label="Interview Date" v-bind="attrs" v-on="on" hide-details="auto" persistent-placeholder
                        readonly>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="interview.date" @input="dateMenu = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6">
                  <!--<v-select v-model="interview.type" :items="types" :error-messages="errorMessages.type" label="Type"
                    hide-details="auto" persistent-placeholder>
                  </v-select>-->
                  <label class="text-caption" :class="errorMessages?.type ? 'red--text' : 'v-label'">Interview Type</label>
                  <v-radio-group
                      v-model="interview.type"
                      dense
                      row
                      hide-details="auto"
                      :error-messages="errorMessages.type"
                      class="mt-n1"
                      >
                      <v-radio color="green" label="Initial" value="Initial Interview"></v-radio>
                      <v-radio color="green" label="Final" value="Final Interview"></v-radio>
                  </v-radio-group>

                </v-col>

              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-menu ref="startTime" v-model="startTimeMenu" :close-on-content-click="false"
                    :return-value.sync="interview.startTime" transition="slide-y-transition" max-width="290px"
                    min-width="290px" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="formatTime(interview.startTime)" label="Start Time"
                        prepend-icon="mdi-clock-start" v-bind="attrs" v-on="on" hide-details="auto"
                        persistent-placeholder readonly></v-text-field>
                    </template>

                    <AppTimePicker v-if="startTimeMenu" v-model="interview.startTime"
                      @click:cancel="startTimeMenu = false" @click:ok="$refs.startTime.save(interview.startTime)" />

                  </v-menu>
                </v-col>

                <v-col cols="12" md="6">

                  <v-select v-model="interview.duration" label="Duration" :error-messages="errorMessages.duration"
                    :items="durationItems" persistent-placeholder>
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea v-model="interview.description" label="Description" hide-details="auto"
                    persistent-placeholder rows="2">
                  </v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select v-model="interview.status" :items="statuses" :error-messages="errorMessages.status"
                    label="Status" hide-details="auto" persistent-placeholder>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save" text>
            Save
          </v-btn>
          <v-btn @click="interviewDialog = false" color="red" text>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </AppFormDialog>
  </AppForm>
</template>

<script>
import AppForm from '../AppForm'
import AppFormDialog from '../AppFormDialog'
import moment from 'moment'
import AppTimePicker from '../AppTimePicker'
import { getErrorMessagePair } from '@/util/errorResponseUtil'
/* import ViewItemAction from '../base/table/ViewItemAction' */
import EditItemAction from '../base/table/EditItemAction'
import DeleteItemAction from '../base/table/DeleteItemAction'
import lodash from 'lodash'

export default {
  name: 'InterviewForm',
  components: {
    AppForm,
    AppTimePicker,
    AppFormDialog,
    /* ViewItemAction, */
    EditItemAction,
    DeleteItemAction
  },

  props: {
    item: Object
  },

  data() {
    return {
        rating: 0,
        rateDialog: false,
      durationItems: [
        {
          text: '5 mins',
          value: 5
        },
        {
          text: '10 mins',
          value: 10
        },
        {
          text: '15 mins',
          value: 15
        },
        {
          text: '30 mins',
          value: 30
        },
        {
          text: '45 mins',
          value: 45
        },
        {
          text: '1 hour',
          value: 60
        }
      ],
      interview: {},
      errorMessages: {},
      /* interviewersRules: [ */
      /*   v => !!v || 'Interviewers is required', */
      /*   v => (v && v.length >= 1) || 'Select alteast one interviewer' */
      /* ], */


      /* dateRules: [ */
      /*   v => !!v || 'Date is required' */
      /* ], */

      /* typeRules: [ */
      /*   v => !!v || 'Type is required' */
      /* ], */

      /* startTimeRules: [ */
      /*   v => !!v || 'Start time is required' */
      /* ], */

      startTimeMenu: false,

      statuses: [
        'Passed',
        'Failed',
        'Rescheduled',
        'Disqualified',
        'Missed'
      ],
      dateMenu: false,
      interviewDialog: false,
      interviewScheduleHeaders: [
        {
          text: 'Date',
          value: 'start_datetime'
        },
        {
          text: 'Interview Type',
          value: 'type',
          align: 'center'
        },
        {
          text: 'Duration',
          value: 'duration'
        },
        {
          text: 'Rating',
          value: 'average_rating',
          align: 'center',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Action',
          value: 'action',
          align: 'center',
          sortable: false
        }
      ]
    }
  },

  computed: {

    // TODO: update store/applicants.js to use response.data instead of custom object then remove this.
    currentApplicant() {
        return this.$store.getters['applicants/getAll']?.find(item => item.id === this.item.id)
    },
    forInterview() {
        return this.currentApplicant?.profile?.status.toLowerCase().indexOf('interview') > -1
    },

    types() {
      const items = [
        'Initial Interview',
        'Final Interview'
      ]

      return items

      /* const passed = this.interviews.filter(interview => */
      /*   interview.type.toLowerCase() === 'initial interview' && interview.status?.toLowerCase() === 'passed' */
      /* ).length */

      /* if (passed) { */
      /*   return items.filter(item => { */
      /*       return item.toLowerCase() === 'final interview' */
      /*   }) */
      /* } */

      /* return items */
    },

    hasPassedFinalInterview() {
      const passed = this.interviews.filter(interview =>
        interview.type.toLowerCase() === 'final interview' && interview.status?.toLowerCase() === 'passed'
      ).length
        
        return passed > 0
    },

    interviewersSelection() {
      return this.$store.getters['interviewers/getAll'].map(interviewer => ({
        fullName: interviewer.first_name + ' ' + interviewer.last_name,
        email: interviewer.email
      }))
    },

    missedInitialInterviews() {
      return this.interviews.filter(interview =>
        interview.type.toLowerCase() === 'initial interview' && interview.status?.toLowerCase() === 'missed'
      ).length
    },

    missedFinalInterviews() {
      return this.interviews.filter(interview =>
        interview.type.toLowerCase() === 'final interview' && interview.status?.toLowerCase() === 'missed'
      ).length
    },

    interviews() {
      return this.$store.getters['interviews/getAll']
    }
  },

  methods: {
      approveApplicant() {
          this.$store.dispatch('prompt/confirm', {
            title: lodash.startCase(status.toLowerCase()),
            message: 'Approve Applicant?'
          }).then(response => {
            if (response) {
              this.$store.dispatch(`applicants/progressForward`, this.item)
            }
          })
      },

      submitRate(item, rating) {
          this.$axios.post(`interviews/${item.id}/rate`, {
            score: rating
          }).then(response => {
              this.$store.dispatch('notifications/addSuccessNotification', "Rated Successfully")
              this.refresh()
              console.log(response)
          }).catch(error => {
            console.log(error)
            this.$store.dispatch('notifications/addWarningNotification', "Rate Unsuccessful")
          }).finally(() => {
              this.rateDialog = false
              this.rating = 0
          })
      },
      onViewRate(item) {
          this.rateDialog = true
          this.interview = Object.assign({}, item)

          this.$axios.get(`interviews/${item.id}/rating`).then(response => {
              this.rating = response.data?.score || 0
          }).catch(error => {
              console.log(error)
          })
      },
    onDelete(item) {
      this.$store.dispatch('prompt/confirm', {
        title: `Delete confirmation`,
        message: 'Are you sure you want to delete?'
      }).then(response => {
        if (response)
          this.$store.dispatch(`interviews/delete`, item)
      })
    },
    onEdit(value) {
      this.interview = Object.assign({}, value)
      this.interview.status = lodash.startCase(lodash.toLower(this.interview.status))
      this.interview.startTime = moment(this.interview.start_datetime, 'YYYY-MM-DD HH:mm:ss').format('hh:mma')
      this.interview.date = moment(this.interview.start_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
      this.errorMessages = {}
      this.interviewDialog = true
    },
    durationToHours(value) {
      let hours = parseInt(value / 60)
      let minutes = value % 60
      let label = ''

      if (hours > 0)
        label = `${hours} ` + (hours > 1 ? 'hrs.' : 'hr.')

      if (minutes > 0)
        label += (hours > 0 ? '&' : '') + ` ${minutes} ` + (minutes > 1 ? 'mins.' : 'min')

      return label
    },

    formatDate(value, format = 'MMMM D, YYYY') {
      let date = moment()

      if (typeof value !== 'undefined')
        date = moment(value, 'YYYY-MM-DD HH:mm:ss')
      else
        this.interview.date = date.format('YYYY-MM-DD')

      return date.format(format)
    },

    formatTime(value) {
      if (typeof value === 'undefined') {
        return this.interview.startTime = moment().format('hh:mma')
      }
      return value
    },

    scheduleInterview() {
      this.interviewDialog = true
      this.interview = {}
    },

    save() {
      this.interview.interviewee_id = this.item.profile.id
      this.interview.start_datetime = this.interview.date.substring(0, 10) + 'T' + moment(this.interview.startTime, 'hh:mma').format('HH:mm')

      if (this.interview.id) {
        this.$store.dispatch('interviews/update', {
          id: this.interview.id,
          params: this.interview
        }).then(() => {
          this.errorMessages = {}
          this.refresh()
          this.interviewDialog = false
        }).catch(error => {
          this.errorMessages = getErrorMessagePair(error.response.data)
        })
        return
      }
      this.$store.dispatch('interviews/save', this.interview).then(() => {
        this.interview = {}
        this.refresh()
        this.interviewDialog = false
      }).catch(error => {
        this.errorMessages = getErrorMessagePair(error.response.data)
      })
    },

    refresh() {
      this.$store.dispatch('interviews/fetchPagination', {
        'filter[and][][interviewee_id][eq]': this.item.profile.id
      })
    }
  },

  mounted() {
    this.$store.dispatch('interviewers/fetchPagination')
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-rating.rating-item-label > * {
    padding: 0px;
}
</style>
