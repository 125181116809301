<template>
  <div>
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon
        color="white"
        @click="toggleNavigationDrawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-img
          alt="AximaPRO"
          contain
          :src="require('../assets/aximapro-logo.png')"
          height="56"
          transition="scale-transition"
        />
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu open-on-hover offset-y close-delay="250">
        <template #activator="{ on, attrs }">
          <v-badge
            color="red"
            :content="notes.length"
            overlap
            :value="notes.length > 0"
          >
            <v-btn color="white" icon large v-bind="attrs" v-on="on">
              <v-icon>mdi-notebook</v-icon>
            </v-btn>
          </v-badge>
        </template>

        <v-card>
          <v-subheader class="text-overline">Note Notification</v-subheader>
          <v-divider></v-divider>
          <v-virtual-scroll
            bench="8"
            width="540"
            :items="notes"
            item-height="80"
            max-height="600"
          >
            <template #default="{ item }">
              <v-list-item :key="item.id" class="mr-3" @click="readNote(item)">
                <v-list-item-avatar color="primary" class="white--text">{{
                  shortenName(item.notifier.fullname)
                }}</v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <strong>{{ item.notifier.fullname }}</strong>
                    {{ item.title }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    {{ item.message }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    formatNoteRelativeFromNow(item.created_at)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-menu>

      <div class="overline white--text mx-2">{{ userEmail }}</div>

      <!-- TODO: Fix menu layer underlapping router-views -->
      <AccountMenu @click:user-settings="showUserSettings"> </AccountMenu>
    </v-app-bar>

    <TheNavigationDrawer ref="navigationDrawer" />

    <v-main>
      <v-container class="fill-height pa-6" fluid>
        <router-view :refs="this.$refs" @notify="notify"> </router-view>

        <TheUserSettingsDialog v-model="userSettingsDialog" />

        <TheConfirmationDialog />

        <TheNotification
          v-for="(notification, index) in notifications.filter(
            (notifs) => notifs.show
          )"
          :key="`__notification-${index}-${Math.random()}`"
          :item="notification"
          :style="`top: ${index * 60 + 10}px`"
          ref="notification"
        />

        <TheLoadingDialog ref="loadingDialog" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AccountMenu from "./AccountMenu"
import TheNavigationDrawer from "./TheNavigationDrawer"
import TheConfirmationDialog from "./TheConfirmationDialog"
import TheLoadingDialog from "./TheLoadingDialog"
import TheNotification from "./TheNotification"
import TheUserSettingsDialog from "./TheUserSettingsDialog"

import { mapState } from "vuex"
import moment from "moment"

export default {
  name: "Home",

  components: {
    AccountMenu,
    TheNavigationDrawer,
    TheConfirmationDialog,
    TheLoadingDialog,
    TheNotification,
    TheUserSettingsDialog,
  },

  data() {
    return {
      userSettingsDialog: false,
      notes: [],
    }
  },

  methods: {
    readNote(item) {
      this.$axios
        .put(`notifications/${item.id}/read`)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    formatNoteRelativeFromNow(value) {
      return moment.unix(value).fromNow()
    },

    shortenName(name) {
      const words = name.split(" ").filter((e) => e)
      const shortened =
        words.length > 1 ? words[0][0] + words[1][0] : name[0] + name[1]
      return shortened.toUpperCase()
    },

    showUserSettings() {
      this.userSettingsDialog = true
    },

    toggleNavigationDrawer() {
      this.$refs.navigationDrawer.toggle()
    },

    notify(data, success = true) {
      if (success) {
        this.$refs.notification.open(
          data,
          "green",
          this.$icons.notificationSuccess
        )
      } else {
        this.$refs.notification.open(
          data,
          "red",
          this.$icons.notificationFailed,
          6000
        )
      }
    },
  },

  computed: {
    ...mapState({
      notifications: (state) => state.notifications.all,
    }),

    userEmail() {
      return this.$store.getters["user/data"].email
    },
  },

  mounted() {
    this.$store
      .dispatch("user/me")
      .then((response) => {
        const eventSource = new EventSource(
          `${process.env.VUE_APP_API_URL}notification?did=${response.data.results.id}`
        )

        eventSource.addEventListener("note", (event) => {
          this.notes = JSON.parse(event.data)
        })
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>
