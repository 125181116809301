<template>
  <AppForm
    v-bind="$props"
    v-on="$listeners"
  >
    <v-row>
      <v-col
        cols="12"
        >
        <v-autocomplete
          v-model="item.payor_id"
          :error-messages="errorMessages.payor_id"
          cache-items
          label="Name"
          :persistent-placeholder="true"
          :items="items"
          :loading="loading"
          @update:search-input="searchApplicant"
          :clearable="!$props.readonly"
          hide-no-data
          hide-details="auto"
          >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        >
        <v-textarea
          v-model="item.description"
          :error-messages="errorMessages.description"
          label="Description"
          :persistent-placeholder="true"
          counter="255"
          hide-details="auto"
          no-resize
          rows="2"
          >
        </v-textarea>
      </v-col>
    </v-row>
    
    <!--<v-row>
      <v-col
        cols="12"
      >
        <v-autocomplete
          v-model="item.product"
          :error-messages="errorMessages.product"
          label="Service Product"
          :persistent-placeholder="true"
          hide-details="auto"
          :items="serviceFees"
          item-value="amount"
          hide-no-data
          cache-items
          multiple
        >
            <template #selection="{ item }">
                <v-chip color="primary" label >
                        <v-chip small left label>{{ formatAmount(item.amount) }}</v-chip>
                    {{ item.name }}
                </v-chip>
            </template>
        </v-autocomplete>
      </v-col>
    </v-row>-->


    <v-row>
        <v-subheader class="text-caption">Service Product</v-subheader>
    </v-row>

    <v-row class="mt-n4">
        <v-col>
            <v-list>
                <v-list-item-group
                multiple>
                    <v-list-item>
                        <v-list-item-action>
                            <v-checkbox>
                            </v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-row>
                                <v-col sm="4" offset-sm="8">
                                    <v-text-field
                                        v-model="cashAmount"
                                        label="Cash amount"
                                        dense
                                        outlined
                                        persistent-placeholder
                                        suffix="$"
                                        @input="divideCashToPayments"
                                        clearable
                                        reverse>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-for="(fee, index) in serviceFees"
                    active-class="primary--text text--accent-4"
                    :key="index">
                        <template #default="{ active }">
                            <v-list-item-action>
                                <v-checkbox
                                    :input-value="active">
                                </v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-row>
                                    <v-col sm="9">
                                        <v-subheader class="justify-space-between px-0">{{ fee.name.replace(/^AximA/i, '') }}
                                            <span class="font-weight-bold">{{ formatAmount(fee.amount) }}</span>
                                        </v-subheader>
                                    </v-col>

            
                                    <v-col sm="3">
                                        <v-text-field
                                            class="text-right"
                                            v-model="payFeeAmounts[index]"
                                            hide-details="auto"
                                            persistent-placeholder
                                            dense
                                            reverse
                                            suffix="$"
                                            outlined
                                            :readonly="!active"
                                            >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-col>
    </v-row>


    <v-row>
      <v-col
        cols="12"
        sm="6">
        <v-select
          v-model="item.type"
          :error-messages="errorMessages.type"
          :items="types"
          label="Payment Type"
          :persistent-placeholder="true"
          hide-details="auto"
          >
        </v-select>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field 
          v-model="item.paid_datetime"
          type="datetime-local"
          :error-messages="errorMessages.paid_datetime"
          label="Paid Date & Time"
          :persistent-placeholder="true"
          hide-details="auto"
          >
        </v-text-field>
      </v-col>

      <!--<v-col
        cols="12"
        sm="6">
        <v-text-field
          v-model="item.amount"
          :error-messages="errorMessages.amount"
          prepend-inner-icon="mdi-currency-usd"
          label="Amount"
          type="number"
          step="0.01"
          pattern="^\d+(?:\.\d{1,2})?$"
          :persistent-placeholder="true"
          hide-details="auto"
          >
        </v-text-field>
      </v-col>-->
    </v-row>

    <!--<v-row>
      <v-col
        cols="12"
        >
        <v-text-field
          v-model="item.medium"
          value="Manual"
          :error-messages="errorMessages.medium"
          prepend-inner-icon="mdi-cash"
          label="Medium"
          :persistent-placeholder="true"
          hide-details="auto"
          >
        </v-text-field>
      </v-col>
    </v-row>-->
    <v-row v-if="item.screenshot_path">
      <v-col
        cols="12">
        <v-expansion-panels
          tile
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <label>
                <v-icon>mdi-file-image</v-icon>
                Uploaded Screenshot
              </label>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-img
              :src="item.screenshot_path"
              contain>
              </v-img>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
    </v-row>

    <v-row>
      <v-col
        cols="12"
      >
        <v-file-input
          v-if="isModeCreate || isModeEdit"
          v-model="item.screenshot"
          accept="image/jpeg,image/png"
          :error-messages="errorMessages.screenshot"
          :disabled="$props.readonly"
          label="Screenshot"
          prepend-icon="mdi-camera"
          :persistent-placeholder="true"
          hide-details="auto"
          hint="Must be jpeg or png with max size of 5 MB."
          persistent-hint
          @change="changeScreenshotImage"
          show-size
        >
          <template #selection="{ text }">
            <v-chip
              small
              label
              color="primary">
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
      </v-col>
    </v-row>

    <v-row 
      v-show="item.screenshot"
    >
      <v-col
        cols="12"
      >
        <label class="text-subtitle-2">Screenshot Preview</label>

        <v-img
          :src="screenshotPreviewSrc"
          :width="imageWidth"
          :height="imageHeight"
          contain
          >
        </v-img>
      </v-col>
    </v-row>
  
  </AppForm>
</template>

<script>
import AppForm from '../AppForm'
import ServiceFee from '../../api/serviceFee'
import moment from 'moment'

export default {
  name: 'PaymentForm',
  extends: AppForm,
  components: {
    AppForm
  },

  data() {
    return {
    payFeeAmounts: [],
        cashAmount: '',
      serviceFees: [],
      types: [
        { 
          text: 'Paypal',
          value: 'Paypal'
        },
        { 
          text: 'Bank Transfer',
          value: 'Bank Transfer'
        }
      ],
      loading: false,
      imageWidth: 'auto',
      imageHeight: 'auto',
    }
  },

  computed: {
    items() {
      return this.$store.getters['applicants/getAll']
        .map(({ profile }) => {
          return {
            text: this.getProfileFullname(profile),
            value: profile.id
          }
        })
    },

    screenshotPreviewSrc() {
      return this.item.screenshot ? URL.createObjectURL(this.item.screenshot) : null
    }
  },

  methods: {
    divideCashToPayments() {
        let cashAmount = parseInt(this.cashAmount) || 0

        this.payFeeAmounts.length = 0

        for (let index in this.serviceFees) {
            if (cashAmount <= 0)
                break

            const fee = parseInt(this.serviceFees[index].amount)
            let payFee = cashAmount < fee ? cashAmount : fee
            this.payFeeAmounts[index] = payFee
            cashAmount -= payFee
        }
    },

    formatAmount(value) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        })

        return formatter.format(value)
    },

    parseDateTime(value) {
      console.log(value)
      return moment.parseZone(value).format('YYYY-MM-DDTHH:mm')
    },

    changeScreenshotImage(file) {
      /**
       * TODO: fix image height when it is empty
       *  temporary solution having imageWidth and imageHeight
       */
      if (file) {
        this.imageWidth = 'auto'
        this.imageHeight = 'auto'
      }
      else {
        this.imageWidth = 0
        this.imageHeight = 0
      }
    },

    getProfileFullname(profile) {
      const {
        first_name,
        last_name
      } = profile
      return `${first_name} ${last_name}`
    },

    searchApplicant(text) {
      const currentSelected = this.items.find(({ value }) => 
          value === this.item.payor_id
      )

      if (text && text === currentSelected?.text)
        return 

      this.loading = true
      this.$store.dispatch('applicants/searchByName', text ?? this.item.payor_name).finally(() => {
        this.loading = false
      })
    }
  },
  created() {
    ServiceFee.search().then(response => {
      if (response.data) {
        this.serviceFees = response.data.items.map(item => ({
          text: `${item.name} ($${item.amount})`,
          ...item
        }))
      }
    })
  }
}
</script>

